.spinnerbackground {
  width: 100%;
  height: 100%;
  /*   background-color: transparent; */
  background: rgba(255, 255, 255, 0.834);
  position: fixed;
  display: flex;
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  z-index: 999;
}