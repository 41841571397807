body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: 'RaajaaMediumMedium';
  src: url('./assets/fonts/Raajaa\ Medium\ Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'AnmolUniBani';
  src: url('./assets/fonts/AnmolUniBani.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'RaajaaBoldBold';
  src: url('./assets/fonts/raajaa_5-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'RaajBold';
  src: url('./assets/fonts/raaj___5-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'AdhiapakMarkerMedium';
  src: url('./assets/fonts/Adhiapak\ Medium\ Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'PrabhkiRegular';
  src: url('./assets/fonts/Prabhki\ Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'KarmicSanjMedium';
  src: url('./assets/fonts/Karmic\ Sanj\ Medium\ Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Puritan20Italic';
  src: url('./assets/fonts//Puritan_Italic-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'AndikaBasicRegular';
  src: url('./assets/fonts/AndBasR-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ArchitectsDaughterRegular';
  src: url('./assets/fonts/ArchitectsDaughter-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'QuattrocentoRomanRegular';
  src: url('./assets/fonts/Quattrocento-Regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'DroidSansRegular';
  src: url('./assets/fonts/DroidSans-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'DroidSansRegular';
  src: url('./assets/fonts/DroidSerif-Bold-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'JaipurRegular';
  src: url('./assets/fonts/jaipur__-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Gurumaa150Bold';
  src: url('./assets/fonts/gurumaa-150b-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'RaghindiRegular';
  src: url('./assets/fonts/raghu-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'gargiMedium';
  src: url('./assets/fonts/gargi-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'CDACGISTYogeshNormal';
  src: url('./assets/fonts/cdacotygn-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'CDACGISTSurekhNormal';
  src: url('./assets/fonts/cdacsrnt-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Noto Sans Gurmukhi';
  src: url('./assets/fonts/NotoSansGurmukhi[wdth,wght].ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'WebAkharSlim';
  src: url('./assets/fonts/webakharslim-webfont.eot');
  src: url('./assets/fonts/webakharslim-webfont.eot?#iefix') format('eot'),
  url('./assets/fonts/webakharslim-webfont.woff') format('woff'),
  url('./assets/fonts/webakharslim-webfont.ttf') format('truetype'),
  url('./assets/fonts/webakharslim-webfont.svg#webfontYlivuWlh') format('svg');
  font-weight: 700;
  font-size: 25px;
  font-style: normal;
}


/* CSS Styles for Printing */
/* @media print {
  button {
    display: none;
  }
  .header, footer, .audio-player,.audio-p, button {
    display: none;
  }

  
  
  .ang-display, .ang-display * {
    border: none !important; 
    margin: 0;
    padding: 0;
    color: #000;
    background: none;
  }

  .container {
    width: 100% !important; 
    margin: 0 !important;  
    padding: 0 !important;  
    box-sizing: border-box; 
  }

  .ang-display {
    margin-top: 0 !important; 
  }

  .ang-wrapper {
    width: 100% !important; 
    margin: 0 !important;
    padding: 0 !important;
    border: none !important; 
  }

  .ang-itm {
    width: 100% !important;
    border: none !important;
    padding: 0 !important;   
    margin: 0 0 2em 0 !important; 
  }

  h1 {
    font-size: 16pt !important; 
  }
  .d-flex, .flex-column, .mt-5, button, .header, .footer {
    display: none !important;
  }
  .non-printable {
    display: none;
  }
} */
@media print {
  /* Hide all buttons and specific elements */
  button, .header, footer, .audio-player, .audio-p {
    display: none !important;
  }
  .ang-display, .ang-display * {
    border: none !important; /* Remove all borders */
    margin: 0;
    padding: 0;
    color: #000;
    background: none;
  }
  /* Set full width and reset styles for printing */
  .container {
    width: 100% !important; /* Full width */
    margin: 0 !important;
    padding: 0 !important;
    box-sizing: border-box;
  }

  .ang-display {
    margin-top: 0 !important;
  }

  .ang-wrapper {
    width: 100% !important; /* Full width */
    margin: 0 !important;
    padding: 0 !important;
    border: none !important; /* No borders */
    background: none !important; /* Remove background colors */
  }

  .ang-itm {
    width: 100% !important;
    border: none !important; /* No borders */
    padding: 0 !important;   /* Remove padding */
    margin: 0 0 2em 0 !important; /* Separate items */
    color: #000 !important;  /* Ensure text is black for print */
    background: none !important; /* Remove background colors */
  }

  h1 {
    font-size: 16pt !important;
    margin: 0 0 0 0 !important; /* Ensure spacing after heading */
    color: #000 !important; /* Ensure heading text is black */
    text-align: center; /* Center heading text if needed */
  }
  div {
    font-size: 14pt !important;
        color: #000 !important; 
  }

  /* Remove non-essential elements */
  .non-printable {
    display: none !important;
  }
}

